import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout' 
import FooterBranding from '../components/footer/FooterBranding'

const MentionsLegales = () => (
  <Layout>

    <div className="container custom-container">
          <div className="container-inner">
            prochainement...
            <Link to="/">retour à l'accueil</Link>
          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default MentionsLegales
